import axios from "axios";

// const base_url = "http://143.244.215.115:3010/api/v1/mtpl/";
const base_url = "https://api.modernizingtrends.com/api/v1/mtpl/";
// const base_url = "http://localhost:3010/api/v1/mtpl/";
// const base_url = "https://e014-103-44-49-160.in.ngrok.io/api/v1/mtpl/";

const api = {
  login: "login",
  checktoken: "check_token",

  getsettings: "get_settings",
  getemployees: "get_employees",
  addemployees: "add_employee",
  updateemployees: "update_employee",

  addbranch: "add_branch",

  addcompany: "add_company",

  addinvoice: "add_invoice",
  updateinvoice: "update_invoice",
  getinvoice: "get_invoice",

  deleteitem: "delete",
  // filetolink: "filedata_to_link",
};

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

const fetch_data = (url, options, token, api_return) => {
  axios
    .post(
      `${base_url}${url}`,
      { ...options },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((responce) => responce.data)
    .then((data) => api_return(data))
    .catch((data) => {
      console.log(data);
      api_return({ status: false });
    });
};
export const get_file_size = (file_link, callback, if_err) => {
  // if(status===false)
  // fetch_data(file_link, {}, "", (data) => {
  //   console.log(data);
  //   callback(data);
  //   //   console.log(data);
  // });
  // console.log();
  // fetch(file_link).then((response) => console.log(response));
  // return;
  axios
    .get(file_link)
    .then((responce) => {
      callback(responce);
      return;
    })
    .catch((err) => {
      console.log(err);
      // get_file_size({ file_link }, callback, if_err);
      return;
    });
  // .then((data) => callback(data))
};
export const check_login = ({ email, password }, callback, if_err) => {
  // if(status===false)
  fetch_data(
    api.login,
    {
      email,
      password: password,
    },
    "",
    (data) => {
      console.log(data);
      callback(data);
      //   console.log(data);
    }
  );
  return;
};
export const check_token = ({ token }, callback, if_err) => {
  // if(status===false)
  fetch_data(api.checktoken, {}, token, (data) => {
    if (!data.status) {
      console.log(data);
      return if_err();
    }
    callback(data);
    //   console.log(data);
  });
  return;
};

export const get_employees = ({ token }, callback, if_err) => {
  // if(status===false)
  fetch_data(api.getemployees, {}, token, (data) => {
    // console.log(data);
    callback(data);
    //   console.log(data);
  });
  return;
};
export const get_settings = ({ token }, callback, if_err) => {
  // if(status===false)
  fetch_data(api.getsettings, {}, token, (data) => {
    // console.log(data);
    callback(data);
    //   console.log(data);
  });
  return;
};
export const add_employee = (
  { name, mobile, email, password, address, type, branch, section, token },
  callback,
  if_err
) => {
  fetch_data(
    api.addemployees,
    {
      name,
      mobile,
      email,
      password: password,
      address,
      type,
      branch,
      section,
    },
    token,
    (data) => {
      callback(data);
    }
  );
  return;
};
export const add_branch = ({ name, address, token }, callback, if_err) => {
  fetch_data(
    api.addbranch,
    {
      name,
      address,
    },
    token,
    (data) => {
      return window.location.reload();
      callback(data);
    }
  );
  return;
};
export const add_company = ({ name, token }, callback, if_err) => {
  fetch_data(
    api.addcompany,
    {
      name,
    },
    token,
    (data) => {
      return window.location.reload();
      callback(data);
    }
  );
  return;
};
export const update_employee = (
  { id, name, mobile, email, address, type, branch, section, token },
  callback,
  if_err
) => {
  fetch_data(
    api.updateemployees,
    {
      update_type: "all",
      id,
      name,
      mobile,
      email,
      address,
      type,
      branch,
      section,
    },
    token,
    (data) => {
      // console.log(data);
      callback(data);
      //   console.log(data);
    }
  );
  return;
};
export const update_employee_password = (
  { id, email, password, token },
  callback,
  if_err
) => {
  // console.log({
  //   id,
  //   email,
  //   password,
  //   token,
  // });
  // return;
  fetch_data(
    api.updateemployees,
    {
      update_type: "reset-password",
      id,
      email,
      password: password,
    },
    token,
    (data) => {
      // console.log(data);
      callback(data);
      //   console.log(data);
    }
  );
  return;
};
export const get_links = (
  { file_arr, invoice_number, path, token },
  callback,
  if_err
) => {
  // if(status===false)
  // console.log("get_links", {
  //   file_arr,
  //   invoice_number,
  //   path,
  // });
  // return;
  fetch_data(
    api.filetolink,
    {
      file_arr,
      invoice_number,
      path,
    },
    token,
    (data) => {
      if (!data.status) {
        console.log(data);
        return if_err();
      }
      console.log(data);
      callback(data);
    }
  );
  return;
};
export const save_invoice = (
  {
    invoice_number,
    branch_id,
    section_id,
    company_id,
    date_time,
    remark,
    options,
    file_arr,
    path,
    token,
  },
  callback,
  if_err
) => {
  // if(status===false)
  console.log("save invoice", {
    invoice_number,
    branch_id,
    section_id,
    company_id,
    date_time,
    remark,
    options,
    file_arr,
    path,
    token,
  });
  // return;
  fetch_data(
    api.addinvoice,
    {
      invoice_number,
      branch_id,
      section_id,
      company_id,
      date_time,
      remark,
      options,
      file_arr,
      path,
    },
    token,
    (data) => {
      if (!data.status) {
        console.log(data);
        return if_err();
      }
      console.log(data);
      callback(data);
      return window.location.reload();
    }
  );
  return;
};

export const get_invoice = ({ token, filters }, callback, if_err) => {
  fetch_data(api.getinvoice, { filters }, token, (data) => {
    if (!data.status) {
      // console.log(data);
      return if_err();
    }
    // console.log(data);
    callback(data);
  });
};
export const update_invoice = (
  { invoice_number, remark, options, old_file_arr, file_arr, path, token },
  callback,
  if_err
) => {
  // if(status===false)
  console.log("update invoice", {
    invoice_number,
    remark,
    options,
    old_file_arr,
    file_arr,
    path,
    token,
  });
  // return;
  fetch_data(
    api.updateinvoice,
    {
      invoice_number,
      remark,
      options,
      old_file_arr,
      file_arr,
      path,
    },
    token,
    (data) => {
      if (!data.status) {
        console.log(data);
        return if_err();
      }
      console.log(data);
      callback(data);
    }
  );
  return;
};
export const delete_item = ({ id, type, token }, callback, if_err) => {
  fetch_data(
    api.deleteitem,
    {
      id,
      type,
    },
    token,
    (data) => {
      return window.location.reload();
      // callback(data);
    }
  );
  return;
};
